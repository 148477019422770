@import "effect-layers-base";

.wall .bg {
  .ingwer & {
    color: $ingwer;
  }
  .minze & {
    color: $minze;
  }
  .apple-elder & {
    color: $apple-elder;
  }
  .apple-mint & {
    color: $apple-mint;
  }
  .lemon & {
    color: $lemon;
  }
  .green-tea & {
    color: $green-tea;
  }
  .rhubarb & {
    color: $rhubarb;
  }
  .cold-brew & {
    color: $cold-brew;
  }
}

;@import "sass-embedded-legacy-load-done:10";