a.go-left,
a.go-right {
  display: block;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  background: $brown;

  .vivitz & {
    background: transparentize($violet, $footer-transparency);
  }

  color: #fff;
  text-align: center;
  line-height: 2.2; /* XXX ugly */
  z-index: 20;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
a.go-left {
  left: 0;
  &:before {
    content: "<";
  }

  .vivitz & {
    border-radius: 0 3px 3px 0;
  }
}
a.go-right {
  right: 0;
  &:before {
    content: ">";
  }

  .vivitz & {
    border-radius: 3px 0 0 3px;
  }
}

.text:before {
  background: rgba(255, 255, 255, 0.5);
  z-index: -1;
  transition: opacity 1s ease-in-out;
  /* maybe keyframes animation instead, and start later? */

  .content-screen & {
    opacity: 1;
  }
}

.text-impressum {
  p,
  h2,
  h3,
  h1 {
    margin-bottom: 1rem;
  }
}

.text .columns {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 980px;
  position: relative;

  .ie & {
    width: 100%;
  }

  .column {
    min-width: 30vw;
    max-width: 50%;
    flex-basis: 50%;
    height: 60vh;
    display: flex;
    flex-flow: column nowrap;
  }

  .row {
    position: absolute;
    bottom: 20%;
    width: 100%;
    margin-left: 3rem;
    @media screen and (max-width: 768px) {
      max-width: 55rem;
      display: flex;
      flex-flow: column nowrap;
      position: relative;
      margin-left: 0;
      bottom: 0;
      p {
        margin: 2rem 3rem 0 3rem;
      }
    }
  }
}

.text .content {
  margin: 0 3rem;
  max-width: 55rem;
}

.text .features {
  margin: 0 3rem;
  max-width: 25rem;
}

.text .image {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  width: 90%;
  box-shadow: 0 0 0 10px #fff;
  margin: auto 0;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100px;
    height: 50px;
  }
  &:before {
    top: -30px;
    left: -40px;
    transform: rotate(-15deg);
    background-image: url(img/titel-hintergrund-weiss.png);
  }
  &:after {
    bottom: -30px;
    right: -50px;
    transform: rotate(-40deg);
    background-image: url(img/titel-hintergrund-braun.png);
  }
}

.features {
  p {
    margin: 0 0 2rem 0;
  }

  img {
    display: block;
    margin: 1rem 0;
  }
}

@media screen and (max-width: 767px) {
  .text .columns {
    flex-flow: column nowrap;
    height: auto;
  }
  .text .columns .column {
    max-width: none;
    width: 100%;
    height: auto;
    flex-basis: auto;
  }
  .text .image {
    display: none;
  }
  .about-5 {
    h2 {
      min-width: 10rem;
    }
    h2 + p {
      display: none;
    }
    .features {
      margin: 0 2rem;
    }
    img {
      float: left;
      margin: 0 0.5rem 0.5rem 0;
    }
  }
}

.salesgroup {
  margin: 0 auto;
  width: 90%;

  h2 {
    max-width: 10em;
    margin: 0 2rem 1rem 0;
  }

  .c-ice & {
    h2 {
      max-width: 14em;
      margin: 0 2rem 0 0;
    }
  }
}

.sales {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  margin: 1rem 0;

  .item {
    background: #f2f2f2;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
    max-width: 30%;
    flex-basis: 30%;

    .c-ice & {
      height: calc(1 * (13vw + 1vh - 1vmin));
    }

    .vivitz & {
      border: 8px solid #e4e4e4;
    }

    display: flex;
    align-items: center;

    position: relative;

    .logo {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 1rem;
      @media screen and (min-width: 768px) {
        padding: 2rem 4rem;
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 100px;
      height: 50px;
      opacity: 0.7;
    }
    &:before {
      top: -20px;
      left: -20px;
      transform: rotate(-5deg);
      background-image: url(img/titel-hintergrund-weiss.png);
    }
    &:after {
      bottom: -20px;
      right: -20px;
      transform: rotate(-23deg);
      background-image: url(img/titel-hintergrund-braun.png);
    }
  }
}

.ie {
  .salesgroup {
    width: 90%;
  }
  .sales img {
    width: 100%;
  }
}

/*
assets/img/about-hirzel.jpg JPEG 1417x943 1417x943+0+0 8-bit sRGB 83.8KB 0.010u 0:00.009
assets/img/about-reto-1.jpg JPEG 1378x1034 1378x1034+0+0 8-bit sRGB 187KB 0.010u 0:00.019
assets/img/about-reto-4.jpg JPEG 1407x1061 1407x1061+0+0 8-bit sRGB 205KB 0.030u 0:00.019
assets/img/about-traktor.jpg JPEG 1439x959 1439x959+0+0 8-bit sRGB 138KB 0.020u 0:00.019
*/

/* disclaimer / impressum *
.text {
  max-width: 40rem;
  padding: 1rem;
  line-height: 1.3;
  background: rgba(255, 255, 255, 0.4);

  h1 { font-size: 2rem; margin: .25rem 0 1rem; }
  h2 { font-size: 1.6rem; margin: .25rem 0; }
  h3 { font-size: 1.4rem; margin: .25rem 0; }
  p { line-height: 1.6; }
}
/* */

;@import "sass-embedded-legacy-load-done:33";