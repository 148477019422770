footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 50;

  transition: transform 0.3s ease-out;
  transform: translateY(250px);

  .vivitz & {
    transform: translateY(200px);
  }

  .traktor {
    background: url(img/traktor.png) no-repeat;
    background-size: contain;
    width: 150px;
    height: 150px;
    position: absolute;
    left: -200px;
    top: -87px;
    z-index: 2;
    transition: left 1s ease-in-out;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      transform: scale(0.5, 0.5);
      transform-origin: 50% 60%;
    }
  }

  .gradient-bar {
    height: 20px;
    background: url(img/footer-gradient.png) no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    position: relative;
    top: 5px;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    .vivitz & {
      display: none;
    }

    .c-ice & {
      display: none;
    }
  }

  .lid {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    z-index: 3;
    position: relative;
    background: url(img/footer-wave.png) repeat-x top left;

    &:before {
      content: "\2227";
      color: $brown;
      background: #eee;
      width: 30px;
      height: 30px;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 0.5s ease-out;
    }

    &:hover:before {
      background: #fff;
    }

    .c-ice & {
      &:hover:before {
        background: none;
      }
    }

    .vivitz & {
      //      background: linear-gradient(to bottom, transparent, transparentize($violet, $footer-transparency) 3%);
      background: transparentize($violet, $footer-transparency);

      &:before {
        color: $violet;
        border-radius: 3px;
      }

      &:hover:before {
        color: $violet;
      }
    }

    .vivitz .start-screen & {
      background: none;
    }

    .c-ice & {
      background: $hemp;
    }
  }

  .footer-open & {
    transform: translateY(0px);

    .lid:before {
      content: "\2228";
    }
  }

  .content {
    background: $brown;

    .vivitz & {
      background: transparentize($violet, $footer-transparency);
    }
  }

  .columns {
    height: 250px;
    display: flex;
    flex: 0 1 auto;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 1rem 80px 1rem;

    .vivitz & {
      height: 200px;
      padding: 0 1rem 50px 1rem;
    }

    .traktorsmoothies & {
      height: 290px;

      padding: 0 1rem 50px 1rem;
    }

    .column {
      flex: 0 1 auto;
      max-width: 100%;
      flex-basis: 33%;
      max-width: 33%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    a {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
    }

    .navigation li {
      margin-top: 1rem;
    }

    .vivitz & {
      li {
        margin-top: 1rem;
      }
    }

    .languages :not(.active) {
      font-weight: normal;
    }
  }
}

.start-screen {
  .gradient-bar {
    opacity: 0;
  }

  .lid {
    background-image: url(img/footer-wave-white.png);

    .vivitz & {
      //      background-image: linear-gradient(to bottom, transparent, transparentize($violet, $footer-transparency) 5%);
      background-image: none;
      background-color: transparentize($violet, $footer-transparency);
    }

    flex-direction: column-reverse;
    align-items: center;

    &:before,
    &:after {
      background: transparent;
      width: auto;
      height: 16px;
      text-align: center;
      color: black;

      .vivitz & {
        color: #fff;
      }
    }

    &:before {
      content: "\2228";
    }

    &:after {
      content: "Smooth mal runter!";
      font-size: 16px;

      [lang="fr"] & {
        content: "Smooth un peu";
      }
    }

    .vivitz & {
      &:after {
        content: "Nimm einen Schluck!";
      }
    }
    .c-ice & {
      height: 3.2rem;

      &:before {
        content: url("img/c-ice/arrow_scroll.png");
        background-position: 0 4px;
        margin-top: 0.3rem;
      }
      &:after {
        margin-top: 0.5rem;
        color: #fff;
        font-size: 1.3rem;
        font-weight: bold;
        content: "SCROLLEN ERWEITERT DEINEN HORIZONT...";
      }
    }
    .c-ice[lang="fr"] & {
      &:after {
        content: "FAITES DÉFILER POUR ÉLARGIR VOTRE HORIZON...";
      }
    }
    .vivitz[lang="fr"] & {
      &:after {
        content: "Bois une gorg" "é" "e!";
      }
    }
  }
}

.biotta-link {
  background: url(img/biotta-logo.png) no-repeat left center;
  background-size: contain;
  display: block;
  height: 3rem;
  width: 100%;
  text-indent: -7000px;
}

.naturadrinks-link {
  background: url(img/vivitz/naturadrinks-logo.svg) no-repeat left center;
  background-size: contain;
  display: block;
  height: 5rem;
  width: 100%;
  text-indent: -7000px;
}

.impressum-link,
.newsletter-link {
  display: block;
  //height: 2rem;
}

.impressum-link-traktor {
  display: block;
  height: 2rem;
}

.data-protection-link-traktor {
  height: 2rem;
  display: block;
}

@media screen and (min-width: 768px) {
  a.newsletter-link {
    font-size: 1.75rem !important;
  }
}

@media screen and (max-width: 767px) {
  footer .columns .column {
    justify-content: flex-start;

    .vivitz & {
      flex-basis: 50%;
      max-width: 50%;

      &.social {
        max-width: 0;
      }
    }
  }

  footer .imprint a {
    height: auto;
    margin-top: 1rem;
  }
  footer .social .facebook-link {
    margin: 0.8rem 0 0 1rem;
  }
  footer .social .instagram-link {
    margin-left: 1rem;
  }

  footer .biotta-link {
    position: absolute;
    bottom: 37px;
    width: 80%;
    left: 1rem;
    height: 3rem !important;
    text-align: center;
    display: block;
  }
}

.facebook-link,
.instagram-link {
  display: inline-block;
  width: 4rem;
  height: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -7000px;
}
.facebook-link {
  background-image: url(img/facebook-square.svg);
}
.instagram-link {
  background-image: url(img/instagram.svg);
}

@media screen and (max-width: 767px) {
  .small-never {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .small-only {
    display: none;
  }
}

;@import "sass-embedded-legacy-load-done:6";