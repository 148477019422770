.smoothie {
  max-width: 980px;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 2rem;
  @media screen and (max-width: 767px) {
    padding-top: 0;
    margin-top: -5rem;
  }
  .ie & {
    width: 100%;
  }

  .bottle {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 50%;
    flex-basis: 50%;
    height: 60%;
    z-index: 20;

    margin-top: 10%;
    /* @media screen and (min-height: 1100px) { margin-top: 20%; } */

    @media screen and (max-width: 767px) {
      max-width: 30%;
      flex-basis: 30%;

      .vivitz & {
        margin-top: 0;
      }
      .wall-open & {
        .vivitz &,
        .c-ice & {
          display: none;
        }
      }
    }
  }

  .content {
    max-width: 50%;
    flex-basis: 50%;

    @media screen and (max-width: 767px) {
      max-width: 70%;
      flex-basis: 70%;
      .wall-open & {
        .vivitz &,
        .c-ice & {
          max-width: 100%;
          flex-basis: 100%;
          .bubble {
            max-width: 70%;
            flex-basis: 70%;
          }
        }
      }
    }

    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;

    > div {
      transition: all 0.5s ease-in-out;
      margin: 0 2rem;
    }
  }

  .front {
    color: $brown;

    .vivitz & {
      min-height: 18rem;
    }
    .c-ice & {
      color: #fff;
    }
  }

  .ingredients {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    z-index: -1;
  }

  .wall-open & .front {
    opacity: 0;
  }
  .wall-open & .ingredients {
    opacity: 1;
    display: block;
    z-index: 1;
  }

  h3 {
    font-size: 26px;
    padding: 0.5rem 0 0 0;
  }

  p {
    margin: 0 0 1rem 0;
  }

  .subline {
    font-weight: normal;
  }

  .light {
    color: #fff;
    .traktorsmoothies & {
      @media screen and (max-width: 340px) {
      }
    }
  }

  .button {
    position: absolute;
    right: 2rem;
    top: 100%;
    color: #fff;
  }
  .button:before {
    content: "ZUTATEN";
  }

  .wall-open & .button {
    top: -50px;
    background: #222;
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    .traktorsmoothies & {
      @media screen and (max-width: 340px) {
        top: -25px;
      }
    }
  }

  .vivitz .wall-open & .button {
    //    background: transparentize(#444, $footer-transparency);
    background: #444;
    padding: 0.4rem 0.7rem 0.5rem 0.7rem;
    z-index: 10000;
    position: absolute;
    @media screen and (max-width: 340px) {
      top: -25px;
    }
  }

  .wall-open & .button:before {
    content: "\2a2f";
  }

  .ingredients p:last-child {
    position: relative;

    @media screen and (min-width: 768px) {
      &:after {
        content: "";
        background: url(img/arrows.png) no-repeat;
        background-size: contain;
        width: 180px;
        height: 100px;
        position: absolute;
        display: block;
        left: -190px;
        bottom: -30px;
      }
    }
  }
}

// Product Overview

.smoothie--link {
  &:nth-child(odd) {
    .bottle-overview {
      margin-top: 65%;
    }
  }
}

.smoothie-overview {
  @extend .smoothie;
  width: 18rem;
  margin: 0 -2rem;

  .bottle-overview {
    @extend .bottle;
    max-width: 110%;
    flex-basis: 110%;
    margin-top: 25%;
    position: relative;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: transform 200ms ease-in-out;
    @media screen and (max-width: 1000px) {
      height: 45%;
    }
    height: 60%;
    &.bottle-scale {
      transform: scale(1.1);
    }
    .bottle-overview__title {
      text-align: center;
      color: darken($title-color, 8%);
      font-weight: bold;
      font-size: 1.4rem;
      background: none;
      position: absolute;
      display: block;
      transition: opacity 400ms ease-in-out;
      top: $title-position;
      @media screen and (max-width: 1400px) {
        top: $title-position-small;
      }
      opacity: 0;
      &.title-opacity {
        opacity: 1;
      }
      &:after {
        content: "";
        box-shadow: 0 0 75px 30px transparentize(#fff, 0.3);
        display: block;
        width: 100%;
        top: -13px;
        position: relative;
        z-index: -100;
      }
    }
  }
}

// News

.news-1 {
  .news-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .news-text {
    margin-bottom: 1rem;
  }
}

;@import "sass-embedded-legacy-load-done:26";