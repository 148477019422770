@charset 'utf-8';
@import "scss/_reset";

body {
  box-sizing: border-box;
  &.nav-is-open {
    // position: fixed;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

$brown: #522a00;
$kale: #4c8500;
$ananas: #d76d00;
$himbeere: #cd006d;
$mango: #d43b14;
$granatapfel: #523275;
$orange: #f79604;

$ingwer: #ffd82d;
$minze: #9fd0a8;
$apple-elder: #147454;
$apple-mint: #c42525;
$lemon: #69167e;
$green-tea: #8cc34f;
$rhubarb: #ba1a70;
$cold-brew: #c99b24;
$hemp: #66a17b;

$violet: #472f29;
$footer-transparency: 0.2;

$wall-bottom-image-url: "img/vivitz/wall-bottom.jpg";
$wall-top-image-url: "img/vivitz/wall-top.jpg";

$start-slide-image-url-de: "img/vivitz/start-de.jpg";
$start-slide-image-url-fr: "img/vivitz/start-fr.jpg";
$start-slide-h1-image-url-de: "img/vivitz/vivitz-name-de.png";
$start-slide-h1-image-url-fr: "img/vivitz/vivitz-name-fr.png";
// $start-slide-background:  url($start-slide-image-url) center top no-repeat;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;
$stroke-width: 100%;

// Navigation
$stroke-color: $brown;
$overlay-color: transparentize($violet, $footer-transparency);
$text-align: middle;
$justify-content: center;
$container-height: 100%;
$overlay-position: 30%;
$border-top: none;
$fadeIn: fadeInTop;
$translate: -50%;
$overlay-top: 0;

// Products
$title-color: $granatapfel;
$title-position: -6%;
$title-position-small: 0;

$nav-toggle-transition: 0.25s ease-in-out;

%transition-ease {
  transition: 0.2s ease;
}

@font-face {
  font-family: "AmsiProCond";
  src: url(fonts/Stawix_-_AmsiProCond-Regular.woff);
  font-weight: normal;
}
@font-face {
  font-family: "AmsiProCond";
  src: url(fonts/Stawix_-_AmsiProCond-Bold.woff);
  font-weight: bold;
}
@font-face {
  font-family: "AmsiProCond";
  src: url(fonts/Stawix_-_AmsiProCond-Black.woff);
  font-weight: 900;
}

@font-face {
  font-family: "Nexa Rust Handmade";
  src:
    url("./fonts/Fontfabric-Nexa-Rust-Handmade-Condensed1.ttf")
      format("truetype"),
    url("./fonts/Fontfabric-Nexa-Rust-Handmade-Condensed1.svg") format("svg"),
    /* Legacy iOS */ url("./fonts/NexaRustHandmade.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-family: AmsiProCond, sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
  color: $brown;
}

html.impressum {
  overflow-y: visible;
  .wrappy {
    position: static;
    overflow: visible;
  }

  .text-impressum {
    margin: 2rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  h3 {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }
  p {
    font-weight: normal;
  }
}

.wrappy {
  display: inline-block; /* ensure .smoothies margin does not move outside box */
  width: 100%;
  overflow: hidden;
  position: fixed; /* necessary for iOS -- will not stop scrolling on touch otherwise */
}

.bubble {
  color: #fff;
  font-size: 35px;
  padding: 1.6rem 2rem 1.3rem 2rem;
  text-align: center;
  font-weight: 900;
  background-image: url(img/vivitz/title-background-about.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 -1.6rem 1rem -1.6rem;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    padding: 1.2rem 1rem 1rem;
    margin: 0 -0.5rem 1rem -0.5rem;
  }

  //  @media screen and (max-width: 400px) {
  //    font-size: 18px;
  //  }
}

p {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
}

p.about-text {
  white-space: pre-line;
}

@import "scss/navigation";
@import "scss/footer";
@import "scss/effect-layers-vivitz";
@import "scss/slides-vivitz";
@import "scss/smoothies_vivitz";
@import "scss/content-screens-vivitz";
@import "scss/mixins";

.f3cc {
  --f3cc-background: #472f29;
  --f3cc-foreground: #fff;
  --f3cc-button-background: #444;
  --f3cc-accept-background: #13755a;
  --f3cc-button-foreground: #fff;
}
