@import "smoothies_base";

// Override settings from smoothies_base
.smoothie .light {
  color: inherit;
}
.ingredients.light {
  color: #fff;
}

.button {
  //  background: transparentize($violet, $footer-transparency);
  background: $violet;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  padding: 0.8rem 1rem 0.5rem;
  border-radius: 4px;
  z-index: 10000;
}

// mobile first

.smoothie.apple-elder {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_apfel_holunder_de_ms.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_apfel_holunder_fr_ms.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    left: -25px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 12%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.75) 42%,
      rgba(245, 245, 245, 0.68) 45%,
      rgba(245, 245, 245, 0) 75%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-apple-elder.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
}
.smoothie.apple-mint {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_apfelminze_2022_packshot_de.png);
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_apfelminze_2022_packshot_fr.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    right: -20px;
    top: 26%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 16%,
      rgba(245, 245, 245, 1) 30%,
      rgba(245, 245, 245, 0.78) 46%,
      rgba(245, 245, 245, 0.75) 48%,
      rgba(245, 245, 245, 0) 68%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-apple-mint.png);
  }
}
.smoothie.cold-brew {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_cold_brew_de_greattaste_ms.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_cold_brew_fr_greattaste_ms.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.7;
    left: -30px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 55%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 16%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.8) 36%,
      rgba(245, 245, 245, 0.75) 41%,
      rgba(245, 245, 245, 0) 57%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
  h2 {
    background-image: url(img/vivitz/title-background-cold-brew.png);
  }
}
.smoothie.lemon {
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.7;
    left: -30px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 55%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 16%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.8) 36%,
      rgba(245, 245, 245, 0.75) 41%,
      rgba(245, 245, 245, 0) 57%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_classic_zitrone_de_ms.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_classic_zitrone_fr_ms.png);
    }
  }
  h2 {
    background-image: url(img/vivitz/title-background-lemon.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
  .light {
  }
}
.smoothie.green-tea {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_gruentee_de_ms.png);
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_gruentee_fr_ms.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    right: -10px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 8%,
      rgba(245, 245, 245, 1) 10%,
      rgba(245, 245, 245, 0.75) 26%,
      rgba(245, 245, 245, 0.53) 38%,
      rgba(245, 245, 245, 0) 66%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-green-tea.png);
  }
}

.smoothie.rhubarb {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_rhabarber_2017_de_ms.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_rhabarber_2017_fr_ms.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    left: -25px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 12%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.75) 42%,
      rgba(245, 245, 245, 0.68) 45%,
      rgba(245, 245, 245, 0) 75%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-rhubarb.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
}

.smoothie.pfirsich {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_pfirsich_2024_de_ms.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_pfirsich_2024_fr_ms.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    left: -25px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 12%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.75) 42%,
      rgba(245, 245, 245, 0.68) 45%,
      rgba(245, 245, 245, 0) 75%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-pfirsich.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
}

.smoothie.minze {
  .bottle {
    background-image: url(img/vivitz/bottles/vivitz_apfelminze_2022_packshot_de.png);
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/vivitz_apfelminze_2022_packshot_fr.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    right: -10px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 8%,
      rgba(245, 245, 245, 1) 10%,
      rgba(245, 245, 245, 0.75) 26%,
      rgba(245, 245, 245, 0.53) 38%,
      rgba(245, 245, 245, 0) 66%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-petrol-minze.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
}

.smoothie.ingwer {
  .bottle {
    background-image: url(img/vivitz/bottles/ingwer-geschlossen-de-cropped.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/ingwer-geschlossen-fr-cropped.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    left: -20px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 12%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.75) 42%,
      rgba(245, 245, 245, 0.68) 45%,
      rgba(245, 245, 245, 0) 75%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-yellow-ingwer.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
}

.smoothie.hanf {
  .bottle {
    background-image: url(img/vivitz/bottles/hanf-geschlossen-de.png);
    order: 1;
    [lang="fr"] & {
      background-image: url(img/vivitz/bottles/hanf-geschlossen-fr.png);
    }
  }
  .smoothie-gradient {
    @media screen and (max-width: 420px) {
      display: block;
    }
    transition: opacity 1s ease;
    .wall-open & {
      opacity: 0;
    }
    opacity: 0.8;
    left: -25px;
    top: 20%;
    display: none;
    width: 80%;
    position: absolute;
    height: 40%;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(245, 245, 245, 1) 12%,
      rgba(245, 245, 245, 1) 18%,
      rgba(245, 245, 245, 0.75) 42%,
      rgba(245, 245, 245, 0.68) 45%,
      rgba(245, 245, 245, 0) 75%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=1 );
  }
  h2 {
    background-image: url(img/vivitz/title-background-orange-hanf.png);
  }
  p:last-child:after {
    left: initial;
    right: -180px;
    transform: scaleX(-1);
  }
}

//
//@media screen and (min-width: 768px) {
//
//  .smoothie.apple-elder {
//    .bottle {
//      background-image: url(img/vivitz/bottles/apfel-holunder-geschlossen-de.png); order: 1;
//      [lang=fr] & { background-image: url(img/vivitz/bottles/apfel-holunder-geschlossen-fr.png);}
//    }
//  }
//  .smoothie.apple-mint {
//    .bottle {
//      background-image: url(img/vivitz/bottles/apfel-minze-geschlossen-de.png);
//      [lang=fr] & { background-image: url(img/vivitz/bottles/apfel-minze-geschlossen-fr.png);}
//    }
//  }
//  .smoothie.lemon {
//    .bottle {
//      background-image: url(img/vivitz/bottles/classic-zitrone-geschlossen-de-saldo.png); order: 1;
//      [lang=fr] & { background-image: url(img/vivitz/bottles/classic-zitrone-geschlossen-fr-saldo.png);}
//    }
//  }
//  .smoothie.green-tea {
//    .bottle {
//      background-image: url(img/vivitz/bottles/gruentee-geschlossen-de.png);
//      [lang=fr] & { background-image: url(img/vivitz/bottles/gruentee-geschlossen-fr.png);}
//    }
//  }
//  .smoothie.rhubarb {
//    .bottle {
//      background-image: url(img/vivitz/bottles/rhabarber-geschlossen-de.png); order: 1;
//      [lang=fr] & { background-image: url(img/vivitz/bottles/rhabarber-geschlossen-fr.png);}
//    }
//  }
//}

;@import "sass-embedded-legacy-load-done:25";