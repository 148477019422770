@use "sass:math";

@import "slides-base";

.slide.start-slide {
  background: url($start-slide-image-url-de) center center / cover no-repeat;
  [lang="fr"] & {
    background-image: url($start-slide-image-url-fr);
  }

  background-position-x: 60%;

  flex-flow: column nowrap;
  justify-content: space-between;
  padding-top: 9vh;

  h1 {
    order: -1; // fix problem with firefox which takes absolute positioned ::before into account
    background: url($start-slide-h1-image-url-de) center center / contain
      no-repeat;
    [lang="fr"] & {
      background-image: url($start-slide-h1-image-url-fr);
    }

    margin-top: 1rem;
    //    margin-left: 20%;
    width: 300px;
    height: 200px;
    text-indent: -4000em;
  }

  h2 {
    background-image: url(img/titel-hintergrund-gruen.png);
    background-size: 100% 100%;
    padding: 4rem 4rem 4.5rem 4rem;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
  }

  .fb-link {
    position: absolute;
    bottom: 10%;
    left: 60%;
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;

    &:after {
      content: "";
      background: url(img/facebook-square.svg) no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1.3em;
      height: 1.3em;
      position: relative;
      bottom: -3px;
      left: -1px;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      width: 200px;
    }
    h2 {
      font-size: 25px;
    }
    .fb-link {
      left: 50%;
    }
  }

  .splash {
    position: absolute;
    z-index: 10;

    top: 0;
    left: 0;
    width: 500vw;
    height: 500vh;

    background-color: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transition: opacity 0.3s linear;
    pointer-events: none;

    &--show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .splash__container {
    position: absolute;
    top: 50vh;
    left: 50vw;

    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 15px 0px #333;
    background: url("img/vivitz/preview-lightbox-Vivitz.jpg") center right /
      cover no-repeat;
    background-color: white;

    [lang="fr"] & {
      background: url("img/vivitz/Vivitz_ColdBrew_fr.jpg") center right / cover
        no-repeat;
      @media screen and (max-width: 768px) {
        background: url("img/vivitz/Vivitz_ColdBrew_fr.jpg") center right 30% /
          cover no-repeat;
      }
    }

    @media screen and (max-width: 768px) {
      background: url("img/vivitz/preview-lightbox-Vivitz.jpg") center right 30% /
        cover no-repeat;
    }

    width: 80vmin;
    min-height: 40vh;
    border-radius: 15px;
    @media screen and (min-width: 768px) {
      width: 85vmin;
      height: 60vh;
    }

    @media screen and (min-width: 768px) and (orientation: landscape) {
      width: 80vmin;
      height: 60vh;
    }
  }

  .splash__close {
    $splash-close-size: 0.3rem;
    content: "";
    position: absolute;
    top: $splash-close-size * 4;
    right: $splash-close-size * 4;
    padding: $splash-close-size;
    background: url("img/vivitz/cancel-music.svg") center / contain no-repeat;
    @media screen and (min-width: 768px) {
      //top: -$splash-close-size * 2;
      //right: -$splash-close-size * 2;
      padding: $splash-close-size * 2;
    }
    cursor: pointer;
  }

  .splash__mask {
    $splash-height-abs: 550;
    $splash-width-abs: 850;
    $splash-width-abs-mobile: 500;
    display: flex;
    height: 100%;

    &::before {
      content: "";
      display: block;
      padding-top: math.div($splash-height-abs, $splash-width-abs) * 100%;
      @media screen and (max-width: 768px) {
        padding-top: math.div($splash-height-abs, $splash-width-abs-mobile) *
          60%;
      }
    }
  }

  .splash__image-container {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .splash__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 22%;
    padding-left: 40%;
    @media screen and (max-width: 768px) {
      padding-top: 18%;
      padding-left: 40%;
    }
    @media screen and (max-width: 360px) {
      padding-left: 38%;
    }
  }

  .splash__title {
    font-family: "Nexa Rust Handmade", sans-serif;
    font-size: 6rem;
    color: #fff;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.58);

    @media screen and (max-width: 400px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 768px) and (min-width: 400px) {
      font-size: 3.5rem;
    }
    @media screen and (max-width: 1100px) and (min-width: 768px) {
      font-size: 5rem;
    }

    @media screen and (max-height: 790px) and (min-width: 768px) {
      font-size: 3.5rem;
    }
  }

  .splash__claim {
    font-family: AmsiProCond, Sans-Serif;
    font-size: 3.4rem;
    color: #fff;
    // width: 21rem;
    hyphens: auto;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.58);
    margin-top: 1rem;
    @media screen and (max-width: 1100px) and (min-width: 768px) {
      font-size: 2.8rem;
    }

    @media screen and (max-width: 768px) and (min-width: 400px) {
      font-size: 2.4rem;
      //  width: 15rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 1.3rem;
      margin-top: 0.8rem;
      // width: 10rem;
    }

    @media screen and (max-height: 790px) and (min-width: 768px) {
      font-size: 1.8rem;
      // width: 10rem;
    }
  }

  .splash__button {
    background: #dbb248; /* Old browsers */
    background: linear-gradient(
      to right,
      #dbb248 0%,
      #c99922 15%,
      #ead699 48%,
      #d8ae43 100%
    );
    font-family: AmsiProCond, Sans-Serif;
    font-size: 1.5rem;
    font-weight: 900;
    color: #fff;
    padding: 0.7rem 0;
    width: 12rem;
    border-radius: 10px;
    margin-top: 1.6rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.17);
    box-shadow: 2px 11px 5px -10px rgba(0, 0, 0, 0.46);

    @media screen and (max-width: 768px) and (min-width: 400px) {
      width: 10rem;
      font-size: 1.2rem;
    }
    @media screen and (max-width: 400px) {
      width: 7rem;
      font-size: 0.8rem;
      margin-top: 1rem;
      padding: 0.5rem 0;
    }
  }

  .footer {
    order: 10;

    text-align: center;
    background: url(img/vivitz/start-footer.png) no-repeat;
    background-size: 100% auto;
    height: 7vw;
    width: 100%;
    margin-bottom: 45px;
    position: relative;

    .claim {
      width: 25%;
      max-width: 480px;
      margin-top: 2%;
      position: relative;
      z-index: 1;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 45px;
      position: absolute;
      bottom: -45px;
      background-color: transparent;
      background: transparentize($violet, $footer-transparency);
    }

    @media screen and (max-width: 600px) and (orientation: portrait) {
      margin-bottom: 80px;

      .claim {
        width: 60%;
        top: 20px;
      }

      &:after {
        height: 80px;
        bottom: -80px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:17";