@use "sass:math";

@import "content-screens-base";

.about-1 .image {
  background-image: url(img/vivitz/about_1.jpg);
  padding-bottom: math.div(90% * 800, 1200);
  transform: rotate(-5deg);
}

.about-2 .image {
  background-image: url(img/vivitz/about_2.jpg);
  padding-bottom: math.div(90% * 959, 1439);
  transform: rotate(5deg);
}

.about-3 .image {
  background-image: url(img/vivitz/about_3.jpg);
  padding-bottom: math.div(80% * 1034, 1378);
  transform: rotate(-5deg);
}

.about-4 .image {
  background-image: url(img/vivitz/about_4.jpg);
  padding-bottom: math.div(108% * 943, 1417);
  transform: rotate(5deg);
}

.repet-1 .image {
  background-image: url(img/vivitz/Vivitz_Water_PET_Grafik_Web3.png);
  padding-bottom: math.div(90% * 1358, 1890);
  transform: rotate(5deg);
}

;@import "sass-embedded-legacy-load-done:32";